angular.module('RocketWash').controller(
  'WashSettingsMobileSettingsIndexController',
  (
    $scope, $route, $location, $window, $uibModal,
    userSession, SettingValue, serverConstants, Organization, alertService,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;
    $scope.tabs = [
      'general',
      'push_reminder',
      'push_notifications',
      'rw_online'
    ];
    $scope.tabIndex = 0;

    Organization.get(userSession.organization.id).then((organization) => {
      $scope.organization = organization;
    });

    $scope.organizationInfoFields = ['description', 'recomendations'];

    const reloadSettingValues = () => {
      return SettingValue.query().then((settings) => {
        $scope.settings = settings.filter(x => x.keyType === 'CommonSettingKey');
        $scope.deductionUnits = settings.find(x => x.key === 'mobile_reservation_from_aggregator_deduction_units');
        $scope.deductionAmount = settings.find(x => x.key === 'mobile_reservation_from_aggregator_deduction_amount');
        $scope.recurringDeductionAmount = settings.find(x => x.key === 'mobile_reservation_from_aggregator_recurring_deduction_amount');
        $scope.deltaMinutes = settings.find(x => x.key === 'mobile_reservation_might_be_created_in_minutes');
        $scope.slDescription = settings.find(x => x.key === 'service_location_description_in_mobile_application');
        $scope.sampling = settings.find(x => x.key === 'mobile_reservation_time_slot_sampling');
        $scope.slAddress = settings.find(x => x.key === 'service_location_address_in_mobile_application');
        $scope.slPhoneNumber = settings.find(x => x.key === 'service_location_phone_number_in_mobile_application');
        $scope.slActiveLanguage = settings.find(x => x.key === 'service_location_active_language_in_mobile_application');
        $scope.slAvailableLanguages = settings.find(x => x.key === 'service_location_available_languages_in_mobile_application');
        $scope.slAcceptPayment = settings.find(x => x.key === 'service_location_accept_payment_in_mobile_application');
        $scope.slAllowUnauthorizedReservation = settings.find(x => x.key === 'service_location_allow_unauthorized_reservation_mobile_application');
        $scope.slRequirePaymentForUnauthorized = settings.find(x => x.key === 'service_location_require_payment_for_unauthorized_reservation_mobile_application');
        $scope.slDisplayRating = settings.find(x => x.key === 'service_location_display_rating_mobile_application');
        $scope.slAllowedAuthMethods = settings.find(x => x.key === 'service_location_allowed_auth_methods_mobile_application');

        $scope.pushReminderSettings = [
          settings.find(x => x.key === 'send_mobile_push_reminder_before_reservation'),
          settings.find(x => x.key === 'mobile_push_reminder_before_reservation_title'),
          settings.find(x => x.key === 'mobile_push_reminder_before_reservation_text'),
          settings.find(x => x.key === 'mobile_push_reminder_before_reservation_time_offset'),
        ]

        $scope.pushNotificationsSettings = [
          settings.find(x => x.key === 'send_rocketkassа_new_reservation_push_notifications'),
          settings.find(x => x.key === 'notify_phone_on_reservation_create'),
          settings.find(x => x.key === 'forced_sms_notify_phone_on_reservation_create'),
      ]

        if (!userSession.superuser) {
          $scope.deductionUnits.readonly = true;
          $scope.deductionAmount.readonly = true;
          $scope.recurringDeductionAmount.readonly = true;
        }

        $scope.usualSettings = [
          $scope.slDescription,
          $scope.deltaMinutes,
          $scope.deductionUnits,
          $scope.deductionAmount,
          $scope.recurringDeductionAmount,
          $scope.sampling,
          $scope.slAddress,
          $scope.slPhoneNumber,
        ];

        $scope.rwOnlineSettings = [
          $scope.slActiveLanguage,
          $scope.slAcceptPayment,
          $scope.slAllowUnauthorizedReservation,
          $scope.slRequirePaymentForUnauthorized,
          $scope.slDisplayRating,
          $scope.slAvailableLanguages,
          $scope.slAllowedAuthMethods,
        ];

      });
    };
    reloadSettingValues();

    const beforeSave = (setting) => {
      if (setting.key == $scope.deductionAmount.key) {
        switch ($scope.deductionUnits.value) {
          case 'fixed_amount':
            // setting.value = Math.max(setting.value, 100);  //Min is 100rur
            // setting.value = Math.min(setting.value, 1000); //Max is 1000rur
            break;
          case 'percentage':
            // setting.value = Math.max(setting.value, 10); // Min is 10%
            // setting.value = Math.min(setting.value, 50); // Max is 100%
            break;
          default:
            console.log('Aggregator deduction units are invalid');
        }
      };
      if (setting.key == $scope.sampling.key) {
        let value = $scope.sampling.value
        if (value < 5 || value > 90)
          alertService.show({
            text: 'Значение должно быть больше 5 и меньше 90',
            type: 'error',
            timeout: 1000,
          });
        }
    };

    const afterSave = (setting) => {
      if (setting.key == $scope.deductionUnits.key) {
        switch (setting.value) {
          case 'fixed_amount':
            $scope.deductionAmount.value = 100;
            $scope.save($scope.deductionAmount);
            break;
          case 'percentage':
            $scope.deductionAmount.value = 10;
            $scope.save($scope.deductionAmount);
            break;
          default:
            console.log('Aggregator deduction units are invalid');
        }
      }
    };

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.save = function (setting) {
      beforeSave(setting);
      setting.save().then(() => {
        userSession.reload();
        $route.reload();
        reloadSettingValues().then(() => {
          afterSave(setting);
        });
      });
    };

    $scope.saveOrganization = () => {
      $scope.organization.save().then(() => {
        $scope.userSession.reload();
      });
    };

    $scope.editSlLocation = () => {
      $uibModal.open({
        templateUrl: 'pages/wash/settings/mobile_settings/sl_location_popup.slim',
        controller: 'WashSettingsMobileSettingsSlLocationPopupController',
      });
    };

    $scope.editSlPhotos = () => {
      $uibModal.open({
        templateUrl: 'pages/wash/settings/mobile_settings/sl_photos_popup.slim',
        controller: 'WashSettingsMobileSettingsSlPhotosPopupController',
      });
    };

    $scope.editSlFacilities = () => {
      $uibModal.open({
        templateUrl: 'pages/wash/settings/mobile_settings/sl_facilities_popup.slim',
        controller: 'WashSettingsMobileSettingsSlFacilitiesPopupController',
      });
    };
  },
);
