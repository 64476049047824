angular.module('RocketWash').controller(
  'WashServicesPriceListsFormController',
  ($scope, $translate, priceList, Customer, ServiceLocation, userSession) => {
    // Guard
    $scope.priceList = angular.copy(priceList);
    if (!$scope.priceList) {
      $scope.$dismiss();
    }

    $scope.loadServiceLocation = () => {
      ServiceLocation.get(userSession.service_location.id).then((sl) => {
        $scope.sl = sl;
      });
    };

    // Actions
    $scope.save = () => {
      $scope.saveDisabled = true;
      $scope.priceList.save().then((response) => {
        if (_.isEmpty(response.errors)) {
          $scope.$close(response);
        } else {
          $scope.errors = { create_price_list: response.errors };
        }
      }).finally(() => {
        $scope.saveDisabled = false;
      });
    };

    $scope.getUsers = (value, options = { supplier: false }) => {
      return Customer.typeahead(value).then((items) => {
        items.forEach((user) => {
          user.line1 = [user.name, user.phone].join(' ');
          user.line2 = [$translate.instant(`warehouse.warehouse_operations.form.user_types.${user.userType}`), user.email].join(' ');
        });

        return items;
      });
    };

    $scope.userSelected = (user) => {
      $scope.priceList.userId = user && user.id;
    }
    $scope.loadServiceLocation();
  },
);
