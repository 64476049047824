import { env } from '@env';

angular.module('RocketWash').controller('WashIncomeSalaryReportController', (
  $scope, $uibModal, $translate, $http, Employee, FinancialOperation, viewStore,
  FinancialCenter, ServiceLocation, userSession
) => {
  $scope.allSlsAllowed = ['admin', 'organization_director', 'director'].includes($scope.userSession.getRole())

  $scope.defaultStarsRating = 1;


  $scope.showFiredEmployees = viewStore.data.showFiredEmployees;
  $scope.toggleShowFiredEmployees = () => {
    viewStore.data.showFiredEmployees = !viewStore.data.showFiredEmployees;
    $scope.updatePage();
  }

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;

    if (!viewStore.data.activeServiceLocation) {
      viewStore.store($scope.allSlsAllowed ? { activeServiceLocation: 'all_sls' } : { activeServiceLocation: $scope.serviceLocations[0].id });
    }
    $scope.activeServiceLocation = viewStore.data.activeServiceLocation;

    $scope.updatePage()
  });

  $scope.pds = $scope.pageDataService.new({
    page: 'employee_salary_report',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        {
          name: 'employee_status', type: 'enum', values: [
            { value: 'Работает', label: 'Работает' },
            { value: 'В отпуске', label: 'В отпуске' },
            { value: 'Уволен', label: 'Уволен' },
          ]
        },
      ],
      scopes: ['name', 'employee_status'],
      beforeSend: (filters) => {
        if (!viewStore.data.showFiredEmployees) {
          filters.push({
            attr: 'employee_status',
            condition: 'not_equals',
            value: 'fired',
          });
        }
        if ($scope.activeServiceLocation !== 'all_sls') {
          filters.push({
            condition: 'custom__users_in_service_location',
            value: $scope.activeServiceLocation || -1,
          })
        }
      },
    },
    pg: {
      url: '/wash/employees/salary_report',
      model: Employee,
      query: {
        skipDateFilter: true,
        predicate: 'name',
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.withdraw = (employee) => {
    $uibModal.open({
      templateUrl: 'pages/wash/finance/wash_financial_operations/salary/withdraw/form.slim',
      controller: 'WashFinanceWashFinancialOperationsSalaryWidthdrawFormController',
      resolve: {
        resolvedFinancialOperation: () => {
          const fc = new FinancialCenter(employee.balance);
          fc.userName = employee.name;
          fc.userPhone = employee.phone;

          const result = new FinancialOperation({
            date: new Date(),
            performed: true,
            type: 'FinancialOperationOutcomePaySalary',
            fromSecondFinancialCenter: fc,
            amount: employee.balance.amountAtEnd,
          });
          return result;
        },
      },
    }).result.finally(() => {
      setTimeout($scope.updatePage, 1000);
    });
  };

  $scope.refund = (employee) => {
    $uibModal.open({
      templateUrl: 'pages/wash/finance/wash_financial_operations/salary/refund/form.slim',
      controller: 'WashFinanceWashFinancialOperationsSalaryRefundFormController',
      resolve: {
        resolvedFinancialOperation: () => {
          const fc = new FinancialCenter(employee.balance);
          fc.userName = employee.name;
          fc.userPhone = employee.phone;

          const result = new FinancialOperation({
            date: new Date(),
            performed: true,
            type: 'FinancialOperationIncomeEmployeeSalary',
            toFinancialCenter: fc,
            amount: 0,
          });
          return result;
        },
      },
    }).result.finally(() => {
      setTimeout($scope.updatePage, 1000);
    });
  };

  $scope.fine = (employee) => {
    $uibModal.open({
      templateUrl: 'pages/wash/finance/wash_financial_operations/salary/fine/form.slim',
      controller: 'WashFinanceWashFinancialOperationsSalaryFineFormController',
      resolve: {
        resolvedFinancialOperation: () => {
          const fc = new FinancialCenter(employee.balance);
          fc.userName = employee.name;
          fc.userPhone = employee.phone;

          const result = new FinancialOperation({
            date: new Date(),
            performed: true,
            type: 'FinancialOperationOutcomeFine',
            fromFinancialCenter: fc,
            fromSecondFinancialCenter: fc,
            amount: employee.balance.amount,
          });
          return result;
        },
      },
    }).result.finally(() => {
      setTimeout($scope.updatePage, 1000);
    });
  };

  $scope.rightMenuConfig = {
    width: 5,
    buttons:
      [{
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => {
          $scope.downloadXls();
        },
      },
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls_payslip'),
        action: () => {
          $scope.downloadXlsPayslip();
        },
      },
      {
        type: 'switch',
        action: () => {
          $scope.toggleShowFiredEmployees();
        },
      },
      ],
  };

  $scope.downloadXls = () => {
    window.open(`${env.apiUrl}/wash/salary_report/download_salary_report.xlsx?${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.downloadXlsPayslip = () => {
    window.open(`${env.apiUrl}/wash/salary_report/download_payslip_report.xlsx?${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.setValue = () => {
    viewStore.store({ activeServiceLocation: $scope.activeServiceLocation });
    setTimeout($scope.updatePage, 1000);
  }
});
