angular.module('RocketWash').controller('AdminSettingsActiveLanguagesBaseController', function($rootScope, $scope, $state, $translate, viewStore, ActiveLanguages) {
  var updateLanguages;
  (updateLanguages = function() {
    return ActiveLanguages.query().then(function(data) {
      return $scope.languages = _.map(data, function(o) {
        return new ActiveLanguages(o);
      });
    });
  })();
  return $scope.save = function(code) {
    return code.save();
  };
});
