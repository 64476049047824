angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.admin.settings.active_languages', {
    url: '/active_languages',
    tab: 'active_languages',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsActiveLanguagesBaseController',
        templateUrl: 'pages/admin/settings/active_languages/index.slim',
      },
    },
  });
});
